import React from "react"
import Layout from "../components/layout"
import * as cn from "classnames"
import ProductPreview from "../components/layout/ProductPreview"
import ListTitle from "../components/layout/ListTitle"
import { graphql } from 'gatsby'
import NoProductInfo from "../components/layout/NoProductInfo"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class SubCategoryList extends React.Component {
    state = {
        mode: "list"
    }

    changeMode(mode) {
        this.setState({mode: mode})
    }

    render() {
        const p = this.props, { } = p
        const s = this.state, { } = s
        const musage = p.data.usage
        // const products = p.data.product.edges.filter(({node: product}) => product.usage.title === usage.title)
        const products = p.data.product.edges.filter(({node: product}) => product.usage.some( function (subElement) {
                return subElement.title === musage.title
            }))
        const sum = products.filter(n => n).length

        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - {musage.title}</title>
                    <meta name="description" content={musage.title}/>
                </HelmetDatoCms>
                <div className="breadcrumb">
                    <span>Zastosowanie</span>
                    <span>{musage.title}</span>
                </div>
                <div className="container">
                    <ListTitle title={musage.title} sum={sum} onClick={(mode) => this.changeMode(mode)} />
                    <div className={cn(s.mode)}>
                        {sum === 0 && <NoProductInfo />}
                        {sum > 0 && products.map(({ node: product }, idx) => (
                            <div key={idx}>
                                <ProductPreview product={product} />
                            </div>
                        ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query UsageQuery($slug: String!) {
    usage: datoCmsUsage(slug: { eq: $slug }) {
      title
      slug
    }
    product: allDatoCmsProduct {
      totalCount
      edges {
        node {
          title
          description
          slug
          usage {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
  }
`